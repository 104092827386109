$font-caramel-handwriting: 'Caramel', sans-serif;
$font-default: 'Playfair Display', sans-serif;

$contents-breakpoint-1: 1000px;
$contents-breakpoint-2: 740px;
$contents-breakpoint-3: 480px;
$contents-breakpoint-4: 325px;

$five-word-panel-breakpoint: 1000px;

$background-color: #f8f7f8;
$gold-color: #a99436;
$lightgold-color: #d7c57a;
$gray-color: #b2b2b2;
$darkgray-color: #353535;

$zindex-scrollable-gallery: 12;
$zindex-navbar: 11;
$zindex-big-word-panel: 10;
$zindex-floating-images: 9;
$zindex-contents: 6;
$zindex-separator: 4,
