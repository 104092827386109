@import "../../vars";

.untitled-poem {
  text-align: center;
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 45px;
  margin-top: 40px;

  p {
    margin: 0 !important;
    text-align: center !important;
  }
}

.LandingPage {
  .separator {
    z-index: $zindex-separator;
    position: absolute;
  }

  .section.duc-vo-thuong-ton {
    margin-top: 140px;
    padding-bottom: 170px;
    @media screen and (max-width: $contents-breakpoint-1){
      padding-bottom: 150px;
    }
    @media screen and (max-width: $contents-breakpoint-2){
      padding-bottom: 110px;
    }
    @media screen and (max-width: $contents-breakpoint-3){
      margin-top: 60px;
      padding-bottom: 100px;
    }

    .portrait-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      $size: 4px;
      $color: black;
      img {
        width: 350px;
        --s: 4px; /* control the size */
        padding: $size;
        border: calc(2 * $size) solid #0000;
        outline: 1px solid #000;
        outline-offset: calc(-1 * $size);
        background: conic-gradient(from 90deg at 1px 1px, #0000 25%, #000 0);
        @media screen and (max-width: $contents-breakpoint-3){
          width: calc(100vw - 30px);
        }
      }
    }

    .separator-1-2 {
      background-color: $lightgold-color;
      left: -20vw;
      width: 230vw;
      max-width: 3800px;
      top: -226px;
      @media screen and (max-width: $contents-breakpoint-1){
        top: -172px;
      }
      @media screen and (max-width: $contents-breakpoint-2){
        top: -110px;
      }
      @media screen and (max-width: $contents-breakpoint-3){
        width: 245vw;
        left: -27vw;
        top: -80px;
      }
      @media screen and (max-width: $contents-breakpoint-4){
        width: 245vw;
        left: -27vw;
        top: -40px;
      }
    }
  }

  .section.hanh-trinh-hoa-do {
    padding-top: 150px;
    padding-bottom: 50px;
    @media screen and (max-width: $contents-breakpoint-1){
      padding-top: 100px;
    }
    @media screen and (max-width: $contents-breakpoint-2){
      padding-top: 80px;
    }
  }

  .section.tai-lieu-kinh-thu {
    padding-bottom: 190px;
    @media screen and (max-width: $contents-breakpoint-1) {
      padding-bottom: 140px;
    }
    @media screen and (max-width: $contents-breakpoint-2) {
      padding-bottom: 100px;
    }
  }

  .contents {
    .title {
      //text-shadow: 1px 0 1px #0095d7;
      z-index: 2;
      position: relative;
      background: $background-color;
      text-align: center;
      margin-top: 100px;
      margin-bottom: 70px;
      box-sizing: border-box;
      h1, h3 {
        margin: 0;
        span {
          font-size: 120%;
        }
      }
      h3 {
        letter-spacing: 0.5px;
        font-size: 25px;
        @media screen and (max-width: $contents-breakpoint-2) {
          font-size: 22px;
        }
        @media screen and (max-width: $contents-breakpoint-3){
          font-size: 17px;
          font-size: 4vw;
        }
      }
      h1 {
        font-weight: bold;
        font-size: 49px;
        margin-top: -10px;
        @media screen and (max-width: $contents-breakpoint-2) {
          font-size: 37px;
        }
        @media screen and (max-width: $contents-breakpoint-3){
          font-size: 23px;
          font-size: 6.5vw;
        }
      }
    }

    .heading {
      font-family: $font-caramel-handwriting;
      font-size: 50px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 40px;
      @media screen and (max-width: $contents-breakpoint-1){
        font-size: 50px;
      }
      @media screen and (max-width: $contents-breakpoint-2){
        font-size: 38px;
      }
      @media screen and (max-width: $contents-breakpoint-3){
        font-size: 38px;
      }
    }
  }
}

.quote-with-marks {
  margin-top: 45px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &::before {
    content: ",,";
    transform: rotateX(180deg) rotateY(180deg);
    position: absolute;
    opacity: 0.4;
    align-self: start;
    z-index: -1;
    color: $gold-color;

    margin-left: -5px;
    margin-top: 30px;
    font-size: 120px;
    letter-spacing: -13px;
    @media screen and (max-width: $contents-breakpoint-2) {
      margin-left: -3px;
      margin-top: -25px;
      font-size: 90px;
      letter-spacing: -10px;
    }
    @media screen and (max-width: $contents-breakpoint-3) {
      display: none;
    }
  }
  p {
    text-align: justify;
    font-style: italic;
    width: 90%;
    line-height: 25px;
  }
  .origin {
    margin-top: 10px;
    text-align: right;
    width: 100%;
    color: #cbb44c;
  }
  .highlight {
    color: $gold-color;
    font-weight: bold;
  }
}
