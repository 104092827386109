@import "../../vars";

.KinhSachPage {
  h4 {
    text-align: center;
  }

  .section {
    &.part-1, &.part-2, &.part-3 {
      margin-top: 50px;
    }
  }

  ul.kinh-list {
    li {
      margin-bottom: 5px;
    }

    a {
      color: $gold-color;
    }
  }
}
