@import "../../vars";

.lotus-divider {
  img {
    color: $lightgold-color;
    width: 20px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
