@import "./components/vars";

p {
  line-height: 26px;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
