@import "../../vars";

.HichPage {
  .section.text {
    p, h4 {
      margin-bottom: 15px;
    }
  }
  .section.images {
    margin-top: 50px;
    img {
      width: 100%;
    }
  }
}

