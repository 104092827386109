@import "../../vars";

$gold-color: #eed97e;
$panel-width: 500px;

.FourLinePanelImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    width: $panel-width;
  }

  @media screen and (max-width: $panel-width) {
    display: none;
  }
}

.FourLinePanel {
  $border-width: 6px;
  margin: 20px 0;
  z-index: $zindex-big-word-panel;
  background-image: radial-gradient(#313131 5%, black 95%);
  color: $gold-color;
  line-height: 60px;
  border: $border-width solid $gold-color;
  padding: 40px 40px;
  position: relative;
  width: $panel-width;
  height: 450px;
  box-sizing: border-box;

  @media screen and (max-width: $panel-width) {
    display: none;
  }

  .inner {
    border-radius: 1px;
    width: 100%;
    height: 100%;
    border: $border-width solid $gold-color;
    padding: 15px 55px 20px;
    box-sizing: border-box;
    text-align: center;

    .prelude {
      h1.di-lac-ton-phat {
        font-size: 25px;
      }

      h2.an-lenh {
        font-size: 20px;
        margin-top: -25px;
      }
    }

    .justify-all {
      text-align: justify;
      text-align-last: justify;

      ::after {
        content: "";
        display: inline-block;
        width: 100%;
      }
    }

    div.four-lines {
      display: flex;
      justify-content: center;

      h1 {
        font-size: 20px;
        line-height: 36px;
        width: 100%;
      }
    }

    .exitlude {
      margin-top: 30px;
      p {
        font-size: 13px;
        line-height: 17px;
      }
    }
  }
}