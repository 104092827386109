@import '../../vars';

.DocTiep {
  padding: 10px 20px;
  text-align: right;
  margin-top: 50px;
  border-top: 1px solid #b2b2b2;

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  div.page {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
