@import '../../../components/vars';

.contents-container {
  overflow: hidden;
  font-size: 18px;

  .section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    text-align: justify;

    // Move qua kiểu này, đặt class để refactor từ từ
    &.section-2 {
      .contents {
        padding-bottom: 40px;
        h4 {
          font-weight: normal;
          font-family: $font-caramel-handwriting;
          font-size: 50px;
          text-align: center;
          margin-bottom: 20px;
          letter-spacing: 1px;

          &::before {
            content: '-';
            margin-right: 10px;
          }

          &::after {
            content: '-';
            margin-left: 10px;
          }
        }
        b {
          color: $gold-color;
        }
        p {
          margin-top: 20px;
          text-align: justify;
        }
      }
    }

    &.navbar {
      z-index: $zindex-navbar;
    }

    // Heading of the entire page
    &.heading {
      z-index: $zindex-contents;
      margin-top: 70px;
      margin-bottom: 50px;
      text-align: center;

      h1 {
        font-family: $font-default;
        font-size: 40px;
        @media screen and (max-width: $contents-breakpoint-2) {
          font-size: 35px;
        }
        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 30px;
        }
      }
      h2 {
        font-size: 40px;
        font-weight: 100;
        font-family: $font-caramel-handwriting;
      }
    }

    // Normal contents
    .contents {
      z-index: $zindex-contents;
      width: 600px;
      @media screen and (max-width: $contents-breakpoint-2) {
        width: 480px;
      }
      @media screen and (max-width: $contents-breakpoint-3) {
        width: calc(100vw - 20px);
      }

      .text {
        p {
          margin-bottom: 20px;
          text-align: justify;
        }
      }
    }

    p.click-more {
      margin-top: 40px;
      text-align: left;
    }
  }
}
