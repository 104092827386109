@import './components/vars';

body {
  margin: 0;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  color: #3774bb;
}

