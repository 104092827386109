@import "../../vars";

.AboutPage {
  .section {

    .img-container {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .quote-with-marks {
      margin-bottom: 20px;
      margin-top: 20px;

      &::before {
        margin-top: 70px;
      }
    }

    .poem {
      margin-top: 20px;
      font-size: 24px;
      width: 100%;
      p {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
      }
    }

    .sign-off {
      width: 100%;
      text-align: center;
      margin-top: 50px;
    }

    span.lead-word {
      font-family: $font-caramel-handwriting;
      font-size: 30px;
    }
  }
}