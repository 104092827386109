@import "../../vars";

.AnhHoatDong2Page {
  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    div.image {
      flex: 100%;

      img, iframe {
        width: 100%;
      }
    }
  }
}

.scrollable-album {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-scrollable-gallery;
  background: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .inner {
    margin-top: 50px;
    width: 900px;

    div.title {
      margin-bottom: 20px;
      h1 {
        font-family: $font-default;
        font-weight: normal;
        text-align: center;
      }
    }

    .controls {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      $bg-color: #d5b022;

      .album-title {
        padding: 2px 10px 2px 5px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        margin-right: 15px;
        font-size: 35px;
        margin-bottom: 15px;
      }

      span.status {
        margin-right: 10px;
      }

      button {
        font-family: $font-default;
        background: $bg-color;
        color: black;
        border: none;
        font-size: 15px;
        height: 25px;
        cursor: pointer;
        min-width: 25px;

        &:disabled {
          opacity: 0.2;
        }

        &:hover:enabled {
          background: #93760a;
        }

        &:active:enabled {
          background: #463803;
        }

        &.prev {
          margin-right: 2px;
        }

        &.next {
          margin-right: 5px;
        }
      }
    }
  }

  .img-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 70vh;
    }
  }
}


.AnhHoatDong {
  .album-list {
    display: flex;
    padding-left: 0;
    flex-direction: column;
    li {

      span:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
