@import "../../vars";

.AboutUsPage {
  .section {
    span.gold {
      color: $gold-color;
      font-weight: bold;
    }

    ul, ol {
      padding-left: 20px;
      margin: 0;
      margin-top: 15px;

      li {
        line-height: 35px;
      }
    }

    &.section-2 {

      p.contact-email {
        @media screen and (max-width: 415px) {
          text-align: left;
        }
      }
    }
  }
}
