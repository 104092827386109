@import '../../vars';

.MenuIcon {
  margin-left: 15px;
  border: 1px solid $gray-color;
  border-radius: 2px;
  padding: 2px 4px;
  cursor: pointer;

  &:hover, &.show {
    background: $darkgray-color;
    border: 1px solid $darkgray-color;

    div {
      background-color: $gold-color;
    }
  }

  div {
    width: 20px;
    height: 2px;
    background-color: $darkgray-color;
    border-radius: 30px;
    margin: 4px 0;
  }
}
