@import "../../vars";

$gold-color: #eed97e;

.big-word-panel-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.FiveWordPanel {
  z-index: $zindex-big-word-panel;
  text-align: center;
  line-height: 57px;
  border: 2px solid $gold-color;
  padding: 30px 10px;
  font-size: 14px;
  background-image: radial-gradient(#313131 5%, black 95%);
  color: $gold-color;
  text-shadow: 1px 1px 2px #000000;
  width: 130px;
  box-shadow: 7px 7px 20px black;
  display: inline-block;
  margin-bottom: 30px;
  @media screen and (min-width: $contents-breakpoint-1){
    position: absolute;
    top: -270px;

    &.left {
      left: -190px;
    }

    &.right {
      right: -190px;
    }
  }
  @media screen and (max-width: $contents-breakpoint-1) {
    padding: 20px 10px;
    &:first-child {
      margin-right: 15px;
    }
  }

  .inner {
    border-radius: 1px;
    width: 130px;
    border: 2px solid $gold-color;
    padding: 30px 15px;
    box-sizing: border-box;
  }
}