@import "../../vars";

.LuocSuPage {
  b {
    color: $gold-color;
    font-weight: bold;
  }

  div.section.heading {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 50px;
    h1 {
      font-size: 40px;
      font-family: $font-default;
      font-weight: bold;
    }
    h2 {
      font-size: 40px;
      font-weight: 100;
      font-family: $font-caramel-handwriting;
    }
  }

  div.section.navigator {
    margin-top: 40px;
    a {
      text-decoration: none;
      color: inherit;
      font-size: 20px;
      border: none;
      border-bottom: 2px solid $lightgold-color;
      background: none;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        background: $lightgold-color;
      }
      &.next {
        float: right;
      }
      &.prev {
        float: left;
      }
    }
  }

  div.chuong {
    .title {
      font-weight: 800;
      text-align: left;
      font-size: 20px;
      margin-bottom: 20px;

      span {
        background-color: $lightgold-color;
        padding: 0 10px;
      }
    }
  }

  .caption {
    font-size: 13px;
    margin-top: 10px;
    max-width: 60%;
    text-align: center;
  }

  .hinh-anh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    .images {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      img {
        min-height: 270px;
        margin: 0 auto;
      }
    }
  }

  .quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    .quote {
      width: 80%;
      font-style: italic;
      p.caption {
        float: right;
        font-style: normal;
        &::before {
          content: '- '
        }
      }
    }
  }

  .poem-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    .poem {
      font-style: italic;
      margin-top: 20px;
      p {
        text-align: center;
        &.line {
          text-align: center;
          margin-bottom: 3px !important;
          font-size: 15px;
        }
        &.title {
          font-style: normal;
          font-family: $font-caramel-handwriting;
          font-size: 30px;
          text-align: center;
          margin-bottom: 15px;
        }
        &.source {
          &::before{
            content: '- ';
          }
          font-size: 13px;
          text-align: right;
          margin-top: 15px;
          font-style: normal;
        }
      }
    }
  }

  .thong-lenh-container {
    .thong-lenh {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .caption {
        font-size: 13px;
      }
    }
  }
}
